#provider-container, #provider-container-mobile {
  margin-bottom: 40px;

  #lang-selector{
    display: inline-flex;
    margin-top: -20px;
    margin-bottom: -10px;

    #lang-label {
      font-size: 16px;
      color: grey
    }

    .lang {
      margin-left: 10px;
      font-size: 16px;
      color: grey;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: black;
      }
    }
  }

  #headline-container {
    background: linear-gradient(266deg, rgb(229 229 255) 0%, rgb(255 218 218) 100%);
    margin-top: 30px;
    display: inline-flex;
    border-radius: 15px;

    #text {
      padding-left: 50px;
      padding-top: 60px;

      h2 {
        font-size: 45px;
        font-weight: bold;
        margin-top: 5px;
        word-spacing: 4px;
        padding-right: 10px;
      }

      h3 {
        width: 580px;
        margin-top: 35px;
        font-size: 27px;
      }

      #descr {
        margin-top: 20px;
        font-size: 20px;
        width: 580px;
      }

      #get-started-button {
        background: linear-gradient(178deg, rgb(207, 94, 73) 0%, rgb(187 68 46) 100%);
        border: 2px solid #dda0a0;
        padding-left: 25px;
        padding-right: 26px;
        height: 50px;
        margin-top: 40px;
        font-size: 20px;

        &:hover {
          background: linear-gradient(178deg, rgb(196, 87, 67) 0%, rgb(171 54 33) 100%);
          box-shadow: unset;
        }
      }
    }

    #vr-on-phone-img {
      width: 550px;
      margin-left: 60px;
      border-radius: 15px;
    }
  }

  #what-is-ventrace-container {
    margin-top: 130px !important;

    #wiv-bg {
      margin-bottom: -580px;
    }

    h2 {
      text-align: center;
      font-size: 40px;
      margin-top: 5px;
      word-spacing: 4px;
    }

    #wiv-descr {
      margin-top: 35px !important;
      text-align: center;
      font-size: 22px;
      margin: 0 auto;
      width: 650px;
      line-height: 30px;
    }

    #show-image-video {
      margin: 0 auto;
      margin-top: 70px !important;
      box-shadow: 1px 1px 13px 1px #00000012;
      text-align: center;
      cursor: pointer;
      font-size: 19px;
      border: 1px solid #cdcdcd;
      border-radius: 11px;
      width: fit-content;
      padding: 7px 22px;

      &:hover {
        background: linear-gradient(110deg, rgb(236 216 255) 0%, rgb(255 188 236) 100%);
        border: 1px solid #fdbcea;
      }
    }

    #yt-image-video {
      position: relative;
      margin-top: 50px !important;
      margin: 0 auto;
      width: 360px;
      height: 640px;
    }
  }

  #why-need-container {
    background: linear-gradient(266deg, rgb(249 249 255) 0%, rgb(255 237 237) 100%);
    margin-top: 260px !important;
    margin: 0 auto;
    width: 100%;
    border: 1px solid #ffebeb;
    padding: 60px 0px;
    border-radius: 20px;

    #why-need-content {
      display: flex;
      justify-content: center;

      #explainer-block {
        width: 620px;

        h2 {
          margin-top: 5px;
          word-spacing: 3px;
          font-size: 35px;
        }

        #wyu-descr {
          margin-top: 25px;
          font-size: 20px;
        }

        #get-indiv-button {

        }
      }

      #business-types {
        margin-left: 130px;
        margin-top: 30px;

        #label {
          font-weight: bold;
          text-align: center;
          font-size: 17px;
          color: #8a767a;
          margin-bottom: -3px;
        }

        #types-list {
          display: grid;
          margin-top: 20px;

          .type {
            box-shadow: 1px 1px 13px 1px #00000012;
            background: white;
            cursor: pointer;
            border: 1px solid #d7c3c3;
            height: 30px;
            width: 400px;
            margin-bottom: 15px;
            border-radius: 10px;
            padding-top: 10px;
            padding-bottom: 10px;

            #tl {
              cursor: pointer;
              text-align: center;
            }

            &:hover {
              border: 1px solid #a95e5e;
              background-color: #ffe4e4;
            }
          }

          .sel {
            border: 1px solid #e99090;
            background: linear-gradient(179deg, rgb(255 255 255) 0%, rgb(255 222 228) 100%);
          }
        }
      }
    }

    #why-need-button {
      margin-top: 50px;
      padding-left: 25px;
      padding-right: 30px;
      padding-top: 12px;
      height: 47px;

      .text-icon {
        font-size: 20px;
        margin-bottom: -3px;
      }
    }
  }

  #watch-collectors {
    margin-top: 80px !important;

    #wc-bg {
      opacity: 0.4;
      margin-bottom: -590px;
    }

    h2 {
      text-align: center;
      font-size: 40px;
      word-spacing: 3px;
    }

    #wc-descr {
      margin-top: 15px;
      text-align: center;
      font-size: 20px;
    }

    #headshots {
      position: relative;
      z-index: 1;
      margin-top: 60px !important;
      display: inline-flex;
      width: 100%;
      justify-content: center;
      margin-left: 19px;

      .hs:not(:last-of-type) {
        margin-right: 80px;
      }

      .hs {
        width: 270px;

        .hs-pp {
          box-shadow: 1px 1px 13px 1px #0000002b;
          border-radius: 15px;
          width: 220px;
        }

        .name {
          text-align: center;
          margin-top: 15px;
          margin-left: -50px;
          font-weight: bold;
          font-size: 17px;
          color: #7a7a7a;
        }

        .quote {
          margin-left: -50px;
          text-align: center;
          font-size: 20px;
        }
      }
    }

    #testimonials-button {
      margin: 0 auto;
      display: block;
      margin-top: 50px;
      padding-left: 22px;
      padding-right: 23px;
      height: 47px;
    }
  }

  #what-ventrace-can-do-container {
    background: linear-gradient(27deg, rgb(249, 249, 255) 0%, rgb(255, 237, 237) 100%);
    padding: 30px 60px;
    padding-bottom: 60px;
    border-radius: 20px;
    border: 1px solid #ffebeb;
    margin-top: 170px !important;

    h2 {
      word-spacing: 2px;
      text-align: center;
      font-size: 40px;
    }

    #benefits-content {
      margin-top: 50px;
      display: inline-flex;

      #benefits-picker {
        width: 400px;

        #benefits-label {
          font-weight: bold;
          color: #8a767a;
          text-align: center;
          width: 498px;
          font-size: 17px;
        }

        #benefits-list {
          display: flex;
          margin-top: 20px;
          width: 580px;
          flex-wrap: wrap;

          .benefit {
            box-shadow: 1px 1px 13px 1px #00000012;
            background: white;
            margin-right: 20px;
            cursor: pointer;
            border: 1px solid #ddd5d5;
            width: 140px;
            margin-bottom: 15px;
            border-radius: 20px;
            padding: 18px 6px;
            text-align: center;

            .benefit-img {
              width: 50px;
            }

            #bl {
              margin-top: 5px;
              cursor: pointer;
              font-size: 17px;
              line-height: 20px;
              padding-left: 2px;
              padding-right: 2px;
            }

            &:hover {
              border: 1px solid #a95e5e;
              background-color: #ffe4e4;
            }
          }

          .sel {
            border: 1px solid #e99090;
            background: linear-gradient(179deg, rgb(255 255 255) 0%, rgb(255 222 228) 100%);
          }
        }
      }

      #benefits-explainer {
        margin-left: 240px;
        margin-top: 46px;

        #expl-h {
          font-weight: bold;
          font-size: 21px;
        }

        #expl-descr {
          padding-right: 5px;
          margin-top: 20px;
          font-size: 20px;
        }

        #learn-more-button {
          margin-top: 30px;
          width: 230px;
          height: 46px
        }
      }
    }
  }

  #how-it-works-container {
    margin-top: 130px !important;

    #hw-bg {
      opacity: 0.5;
      margin-bottom: -670px;
    }

    h2 {
      word-spacing: 2px;
      text-align: center;
      font-size: 40px;
    }

    #how-descr {
      margin-top: 15px;
      text-align: center;
      font-size: 20px;
    }

    #steps-container {
      display: inline-flex;
      width: 100%;
      justify-content: center;
      margin-top: 60px;
      position: relative;
      z-index: 1;

      .step:not(:last-of-type) {
        margin-right: 20px;
      }

      .step {
        box-shadow: 1px 1px 13px 1px #0000002b;
        border: 1px solid #b5b5b5;
        background: linear-gradient(2deg, rgb(255 255 255) 0%, rgb(255 236 236) 100%);
        margin-right: 10px;
        width: 52px;
        height: 42px;
        font-size: 25px;
        text-align: center;
        padding-top: 11px;
        border-radius: 30px;
        cursor: pointer;

        &:hover {
          background: linear-gradient(2deg, rgb(255 255 255) 0%, rgb(246, 219, 219) 100%);
        }
      }

      .sel {
        border: 2px solid #e99090;
        background: linear-gradient(179deg, rgb(255 255 255) 0%, rgb(255 222 228) 100%);
      }

      .over {
        border: 1px solid #90e99f;
        background: linear-gradient(179deg, rgb(255 255 255) 0%, rgb(237, 246, 203) 100%);
      }
    }

    #step-explain-container {
      #step-label {
        font-weight: bold;
        text-align: center;
        margin-top: 35px;
        font-size: 21px;
      }

      .how-works-img {
        display: block;
        margin: 0 auto;
        margin-top: 30px !important;
        margin-bottom: 30px !important;
        width: 130px;
        z-index: 1;
        position: relative;
      }

      #step-descr {
        position: relative;
        z-index: 1;
        font-size: 21px;
        text-align: center;
        margin-top: 15px !important;
        width: 710px;
        margin: 0 auto;
        line-height: 29px;
      }

      .step-learn-more {
        text-decoration: underline;
        font-size: 19px;
        text-align: center;
        margin-top: 32px;
        cursor: pointer;

        &:hover {
          color: #bd4a34;
        }
      }

      #next-step-button {
        position: relative;
        z-index: 1;
        background: linear-gradient(330deg, rgb(118 110 179) 0%, rgb(180, 50, 85) 100%);
        box-shadow: 1px 1px 13px 1px #0000002b;
        border: 2px solid #b05b7f;
        padding-left: 21px;
        padding-right: 31px;
        height: 49px;
        margin-top: 40px !important;
        font-size: 19px;
        margin: 0 auto;
        display: block;
        padding-top: 7px;

        &:hover {
          background: linear-gradient(330deg, rgb(103, 94, 169) 0%, rgb(213 64 104) 100%);
          box-shadow: unset;
        }

        .icon {
          color: white;
          font-size: 31px;
          margin-bottom: -9px;
        }
      }
    }
  }

  #submit-success-container {
    background: linear-gradient(334deg, rgb(255, 255, 255) 0%, rgb(242 255 219) 100%);
    margin-top: 170px !important;
    width: 100%;
    padding: 60px 0px;
    padding-top: 30px;
    border-radius: 20px;
    text-align: center;

    #mail-sent-img {
      width: 100px;
    }

    #mail-sent-text {
      margin-top: 30px;
      font-size: 19px;
    }
  }

  #contact-form-container {
    background: linear-gradient(124deg, rgb(255 249 227) 0%, rgb(255 246 250) 100%);
    margin-top: 140px !important;
    width: 100%;
    border: 1px solid #e9e9e9;
    padding: 60px 0px;
    padding-top: 30px;
    border-radius: 20px;

    h2 {
      text-align: center;
      font-size: 40px;
      word-spacing: 2px;
    }

    #contact-descr {
      text-align: center;
      margin-top: 20px;
    }

    #contact-form {
      margin-top: 20px;
      width: 100%;
      justify-content: center;
      display: grid;
      text-align: center;

      .text-input-container {
        margin-bottom: 0px;

        .input-label {
          text-align: center;
          width: 100%;
        }

        .text-input {
          background: white;
          width: 300px !important;
          text-align: center;
        }
      }

      #submit-form-button {
        margin-top: 37px;
        width: 317px;
      }

    }
  }

  #resources-container {
    margin-top: 130px !important;

    h2 {
      text-align: center;
      font-size: 40px;
    }

    #resources-descr {
      text-align: center;
      margin-top: 20px;
      font-size: 20px;
    }

    #resource-items {
      margin-top: 60px;
      display: grid;
      justify-content: center;

      .res-item {
        cursor: pointer;
        display: inline-flex;
        margin-bottom: 15px;
        border: 1px solid #d3d3d3;
        width: 510px;
        padding: 8px 20px;
        border-radius: 10px;

        .pdf-icon {
          color: #cd3e1c;
          font-size: 24px;
          margin-top: 13px;
        }

        #t {
          margin-left: 10px;

          .resource-name {
            cursor: pointer;
            margin-left: 15px;
          }

          .resource-language {
            cursor: pointer;
            margin-left: 15px;
            margin-top: -2px;
            font-size: 17px;
            color: #424242;
          }
        }

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }

  &-mobile {
    #headline-container {
      margin-top: 5px;
      display: block;
      padding-bottom: 30px;

      #vr-on-phone-img {
        width: 88%;
        margin-top: 30px;
        margin-left: 22px;
      }

      #text {
        padding-left: 25px;
        padding-top: 40px;

        h2 {
          font-size: 48px !important;
          word-spacing: 2px !important;
          line-height: 47px !important;
        }

        h3 {
          width: 90%;
          margin-top: 15px;
          font-size: 25px !important;
        }

        #descr {
          width: 90%;
        }

        #get-started-button {
          width: 93% !important;
          height: 52px !important;
        }
      }
    }

    #what-is-ventrace-container {
      #wiv-bg {
        width: 100%;
        margin-bottom: -530px;
      }

      h2 {
        font-size: 34px !important;
      }

      #wiv-descr {
        width: 90%;
      }

      #show-image-video {
        position: relative;
        z-index: 2;
        margin-top: 150px !important;
        height: 33px;
        padding-top: 14px;
      }
    }

    #why-need-container {
      width: 100%;
      margin-top: 120px !important;
      padding-top: 35px;
      padding-bottom: 25px;

      #explainer-block {
        width: 100% !important;

        h2 {
          text-align: center;
          font-size: 35px !important;
          line-height: 35px;
        }

        #wyu-descr {
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
        }

        #why-need-button {
          height: 66px !important;
          width: 90% !important;
          margin-left: 18px;
        }
      };

      #why-need-content {
        display: block;

        #business-types {
          margin-top: 50px;
          margin-left: 0px;

          #types-list {
            .type {
              width: 88%;
              height: 27px;
              margin: 0 auto;
              margin-bottom: 13px;
            }
          }
        }
      }
    }

    #watch-collectors {
      #wc-bg {
        width: 100%;
        margin-bottom: -1260px;
      }

      h2{
        text-align: center;
        font-size: 35px !important;
        line-height: 35px;
      }

      #wc-descr {
        margin-top: 20px;
      }

      #headshots {
        margin-left: 0px;
        display: block;
        width: 95%;

        .hs {
          margin-bottom: 80px !important;
          margin-right: 0px !important;
          margin: 0 auto;
        }
      }

      #testimonials-button {
        margin-top: -20px !important;
        min-height: 60px !important;
      }
    }

    #what-ventrace-can-do-container {
      margin-top: 90px !important;
      padding: 10px 12px;
      padding-bottom: 30px;
      width: 95% !important;

      h2 {
        text-align: center;
        font-size: 35px !important;
        line-height: 36px;
      }

      #benefits-content {
        margin-top: 20px;
        display: block;

        #benefits-list {
          width: 90% !important;
          margin: 0 auto !important;
          padding-top: 25px;
          padding-left: 12px;

          .benefit {
            width: 127px !important;
            margin-right: 10px !important;

            .benefit-img {
              width: 40px !important;
            }

            #bl {
            }
          }
        }

        #benefits-explainer {
          margin-left: 0px;
          #expl-h {
            text-align: center;
            font-size: 28px !important;
            line-height: 31px;
          }

          #expl-descr {
            text-align: center;
          }

          #learn-more-button {
            height: 50px !important;
          }
        }

        #benefits-picker {
          width: 100%;

          #benefits-label {
            width: 100%;
          }
        }

      }
    }

    #how-it-works-container {
      width: 100%;
      margin-top: 70px !important;

      h2 {
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
        font-size: 35px !important;
        line-height: 36px;
      }

      #hw-bg {
        width: 100%;
      }

      #steps-container {
        margin-top: 35px;
        .step {
          width: 47px;
          height: 36px;
        }
      }

      #step-explain-container {
        #step-label {
          font-size: 23px !important;
        }

        .how-works-image {
          width: 80px;
        }

        #step-descr {
          width: 100%;
          font-size: 20px;
          line-height: 28px;
        }

        #next-step-button {
          height: 50px !important;
        }
      }
    }

    #contact-form-container {
      margin-top: 80px !important;

      h2 {
        padding-left: 2px;
        padding-right: 2px;
        text-align: center;
        font-size: 35px !important;
        line-height: 36px;
      }

      #contact-descr {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 19px !important;
      }

      #submit-form-button {
        height: 50px !important;
      }
    }

    #provider-contact-container {
      margin-top: 30px;

      #phone-box-container {
        width: 88%;
      }

      #email-box {
        width: 88%;
      }
    }

    #resources-container {
      h2 {
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
        font-size: 35px !important;
        line-height: 36px;
      }

      #resource-items {
        .res-item {
          width: 88%;

          .pdf-icon {
            margin-top: 10px;
          }
        }
      }
    }
  }
}